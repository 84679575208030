@font-face {
  font-family: 'fontCustom';
  src: url('../fonts/FontCustom.woff2') format('woff2'),
  url('../fonts/FontCustom.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

.fc {
  font: normal normal normal 14px/1 fontCustom;
  font-family: fontCustom !important;
  speak: none;
  text-transform: none;
  display: inline-block; // Needed to use fontawesome rotations

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
