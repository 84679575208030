/**
This file contains all the colors that are not theme color (green or blue).
In the interest of color separation all color specific css should be added to either this file
or the themes.scss file.
No other css information should be added here.
**/
@each $theme, $themeColorDef in $darkModeColors {
  .#{$theme} {
    .sq-text-success, .sq-icon-success {
      color: $sq-success-color;
    }

    .sq-text-danger, .sq-icon-danger {
      color: $sq-danger-color !important;
    }

    .sq-alert-danger {
      color: $sq-danger-color !important;
      background-color: $sq-bg-danger-color;
    }

    .sq-alert-warning {
      color: $sq-text-dark-warning-color !important;
      background-color: $sq-bg-warning-color;
      border-color: $sq-bg-warning-border-color;
    }

    .dark {
      background-color: rgb(36, 37, 37, 1);
    }


    .sq-fairly-dark-gray, .sq-icon-dark-gray {
      color: #{map-get($themeColorDef, 'sq-fairly-dark-gray')};
    }

    .sq-fairly-dark-gray-always {
      &, &:hover, &:focus {
        color: #{map-get($themeColorDef, 'sq-fairly-dark-gray')};
      }
    }

    .sq-darkish-gray, .sq-icon-darkish-gray {
      color: $sq-darkish-gray;
    }

    .sq-link-color-analysis {
      color: $sq-link-color-analysis;
    }

    .link-black {
      color: $black !important;
    }

    .sq-force-text-gray {
      color: #{map-get($themeColorDef, 'sq-text-color')} !important;
    }

    .sq-status-error {
      color: $sq-status-error-color !important;
    }

    .sq-status-progress {
      color: $sq-status-progress-color !important;
    }

    .sq-status-warning {
      color: $sq-status-warning-color !important;
    }

    .sq-status-good {
      color: $sq-status-good-color !important;
    }

    .sq-status-info {
      color: $sq-status-info-color !important;
    }

    .sq-btn-outline {
      color: #{map-get($themeColorDef, 'sq-text-color')};
      border-color: #{map-get($themeColorDef, 'sq-disabled-gray')};
      background-color: #{map-get($themeColorDef, 'sq-white')};

      &:hover {
        background-color: #{map-get($themeColorDef, 'sq-light-gray')};
      }

      &:focus {
        border-color: (darken#{map-get($themeColorDef, 'sq-darkest-gray')}, 20);
      }

      &:active {
        background-color: #{map-get($themeColorDef, 'sq-dark-gray')};
      }

      &:active:focus, &:active:hover {
        background-color: #{map-get($themeColorDef, 'sq-disabled-gray')};
      }
    }

    .splashScreenLight {
      background-color: #{map-get($themeColorDef, 'sq-light-gray')};
    }

    .fa-panel-color {
      color: #{map-get($themeColorDef, 'sq-light-gray')};
    }

    #wsScrollBtns {
      border-bottom: 1px solid rgba(196, 211, 214, 0.07);

      a[disabled] {
        color: #{map-get($themeColorDef, 'sq-disabled-gray')};
      }
    }

    .bg-warning .tooltip-inner {
      @extend .bg-warning;
      color: #{map-get($themeColorDef, 'sq-text-color')};
    }

    .sq-bg-success {
      background-color: $sq-bg-success-color;
    }

    .sq-bg-danger {
      background-color: $sq-bg-danger-color;
    }

    sq-formula {
      .CodeMirror {
        background-color: #{map-get($themeColorDef, 'sq-light-gray')};
        color: #{map-get($themeColorDef, 'sq-text-color')};
      }
    }

    ui-codemirror {

      // These are some of the styles applied to the .well class
      .CodeMirror {
        border: 1px solid #{map-get($themeColorDef, 'sq-dark-gray')};
        @include box-shadow(inset 0 1px 1px rgba(0, 0, 0, .05));
      }
    }

    .CodeMirror-lint-tooltip {
      background-color: #{map-get($themeColorDef, 'sq-white')};
      border: 1px solid #{map-get($themeColorDef, 'sq-disabled-gray')};
      @include box-shadow(0 5px 10px rgba(0, 0, 0, .2));
    }

    @media screen {
      hr.fr-page-break:after {
        background: #{map-get($themeColorDef, 'sq-white')};
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 1px 1px rgba(0, 0, 0, 0.16);
      }
    }

    div.reportBackupPreview {
      background-color: #{map-get($themeColorDef, 'sq-light-gray')};
    }

    .anchorButtonBorder {
      border: 1px solid #{map-get($themeColorDef, 'sq-light-gray')};
    }

    .sq-btn-default {
      @extend .btn-default;

      &, &:active, &:focus, &:hover, &:active:focus, &:active:hover {
        background-color: #{map-get($themeColorDef, 'sq-white')};
      }
    }

    .sq-btn-warning {
      @extend .btn-warning;
    }

    .reportBackups {
      div[uib-accordion-group] {
        border: 1px solid #{map-get($themeColorDef, 'sq-darkest-gray')};

        .card-header {
          border-bottom: 1px solid #{map-get($themeColorDef, 'sq-darkest-gray')} !important;
        }

        .card-body {
          .versionEntry {
            border-bottom: 1px solid #{map-get($themeColorDef, 'sq-darkest-gray')};

            .clickPreviewPrompt {
              color: #{map-get($themeColorDef, 'sq-text-color')};
            }

            &:hover {
              background-color: #{map-get($themeColorDef, 'sq-white')};
            }
          }
        }
      }
    }

    .selected-template {
      background-color: $backgroundcolor-highlight;

      &:hover {
        background-color: $backgroundcolor-highlight-hover;
      }
    }

    .report-content-modal, .items-from-worksheet-modal {

      .report-worksheets-list {
        border: 1px solid #{map-get($themeColorDef, 'sq-disabled-gray')};
        background-color: #{map-get($themeColorDef, 'sq-light-gray')};

        .active-worksheet {
          background-color: $backgroundcolor-highlight;

          &:hover {
            background-color: $backgroundcolor-highlight-hover;
          }
        }
      }

      .worksheetContainer {
        padding: 5px;
        border: 1px solid #{map-get($themeColorDef, 'sq-disabled-gray')};
        border-radius: 5px;
        background-color: #{map-get($themeColorDef, 'sq-white')};

        img {
          border: 1px solid #{map-get($themeColorDef, 'sq-disabled-gray')};
          min-height: 80px;
        }
      }

      .reportContentUrl {
        textarea {
          border: 1px solid #{map-get($themeColorDef, 'sq-disabled-gray')};
        }
      }

      .reportContentProperties {
        @extend .flexFillOverflow;

        .image, .placeholder {
          border: 1px solid #{map-get($themeColorDef, 'sq-disabled-gray')};
        }

        .properties {
          border-left: 1px solid #{map-get($themeColorDef, 'sq-disabled-gray')};
          border-right: 1px solid #{map-get($themeColorDef, 'sq-disabled-gray')};
          border-bottom: 1px solid #{map-get($themeColorDef, 'sq-disabled-gray')};
        }

        .settings {
          border-top: 1px solid #{map-get($themeColorDef, 'sq-disabled-gray')};
          border-right: 1px solid #{map-get($themeColorDef, 'sq-disabled-gray')};
          border-bottom: 1px solid #{map-get($themeColorDef, 'sq-disabled-gray')};
        }
      }
    }

    .bulkEditModalBody {
      border: 1px solid #{map-get($themeColorDef, 'sq-disabled-gray')};
    }

    .borderGray {
      border: 1px solid #{map-get($themeColorDef, 'sq-disabled-gray')} !important;
    }

    .bulkEditProperties {
      @extend .flexFillOverflow;
      border-left: 1px solid #{map-get($themeColorDef, 'sq-disabled-gray')};
    }

    .reportConfig [disabled] {
      color: gray;
    }

    .seeqContentDropdown {
      & > div {
        color: $sq-side-panel-text-color !important;
      }

      &:hover {
        background-color: #{map-get($themeColorDef, 'sq-dark-gray')} !important;
      }
    }

    .borderColorWhite {
      border-color: #{map-get($themeColorDef, 'sq-white')} !important;
    }

    .error-border {
      // Copy of the bootstraps has-error effect on fields see the form-control-validation mixin in bootstrap source
      border-color: $sq-danger-color !important;
      box-shadow: inset 0 1px 1px rgba($black, 0.075);

      &:focus {
        border-color: darken($sq-danger-color, 10%) !important;
        box-shadow: inset 0 1px 1px rgba($black, .075), 0 0 6px lighten($sq-danger-color, 20%) !important;
      }
    }

    .disabled {
      &, &:hover, &:active, &:focus {
        color: #{map-get($themeColorDef, 'sq-disabled-gray')};
      }
    }

    .hoverHighlightPanel:hover {
      background-color: #{map-get($themeColorDef, 'sq-light-gray')};
    }

    .backgroundColorWhite {
      background-color: #{map-get($themeColorDef, 'sq-white')};
    }

    .backgroundColorOffWhite {
      background-color: #{map-get($themeColorDef, 'sq-darker-gray')};
    }

    .dividerBorderTop {
      border-top: solid 1px #{map-get($themeColorDef, 'sq-darkest-gray')};
    }

    .dividerBorderBottom {
      border-bottom: solid 1px #{map-get($themeColorDef, 'sq-darkest-gray')};
    }

    .resizablePanel {
      .showResizeablePanel {
        background-color: #{map-get($themeColorDef, 'sq-white')};
        border: solid 1px #{map-get($themeColorDef, 'sq-darker-gray')};
      }

      .handle-right {
        &:hover, &:active, &:focus {
          background-color: #{map-get($themeColorDef, 'sq-light-gray')};
        }
      }

      .handle-bottom {
        &:hover, &:active, &:focus {
          background-color: #{map-get($themeColorDef, 'sq-light-gray')};
        }
      }
    }

    .toolOptions {
      border: 1px solid #{map-get($themeColorDef, 'sq-dark-gray')};

      .divider {
        border-top: 1px solid #{map-get($themeColorDef, 'sq-dark-gray')};
      }
    }

    sq-treemap-chart {
      .node {
        background-image: linear-gradient(to left top, rgba(255, 255, 255, .4), rgba(255, 255, 255, 0));

        &.uncertain {
          background-image: repeating-linear-gradient(-45deg, transparent, transparent 5px, rgba(255, 255, 255, .5) 5px, rgba(255, 255, 255, .5) 10px),
          linear-gradient(to bottom, rgba(255, 255, 255, .4), rgba(255, 255, 255, 0));
        }

        &.uncertain:hover {
          background-image: repeating-linear-gradient(-45deg, transparent, transparent 5px, rgba(255, 255, 255, .5) 5px, rgba(255, 255, 255, .5) 10px);
        }

        .btn {
          background-color: #{map-get($themeColorDef, 'sq-light-gray')};

          &:hover {
            background-color: #{map-get($themeColorDef, 'sq-white')};
          }
        }
      }
    }

    #detailsPanelContent {
      .btn.disabled, .btn[disabled], fieldset[disabled] .btn {
        background-color: #{map-get($themeColorDef, 'sq-darker-gray')};
      }
    }

    sq-license-expiration-warning {
      .warning {
        background-color: rgba($backgroundcolor-hover, 0.90);
      }

      a {
        color: #{map-get($themeColorDef, 'sq-text-color')};
      }
    }

    .license-management {
      background-color: #{map-get($themeColorDef, 'sq-darker-gray')};
      color: #{map-get($themeColorDef, 'sq-text-color')};

      .grayTxt {
        color: $sq-side-panel-text-color;
      }
    }

    .autoUpdatePopup {
      .header {
        border-bottom: 1px solid #{map-get($themeColorDef, 'sq-darker-gray')};
        background: #{map-get($themeColorDef, 'sq-light-gray')};
      }

      .errorMsg {
        color: $sq-danger-color !important;
      }
    }

    .toolsSearchBar {
      border-bottom: 1px solid (lighten#{map-get($themeColorDef, 'sq-darkest-gray')}, 20%);
    }

    .tableHighlightBackground {
      background-color: #{map-get($themeColorDef, 'backgroundcolor-table-highlight')} !important;
    }

    .viewing-badge {
      color: #{map-get($themeColorDef, 'sq-dark-gray')};
    }

    .file-drop-zone {
      background-color: #{map-get($themeColorDef, 'sq-light-gray')};
      border: dashed 2px #{map-get($themeColorDef, 'sq-disabled-gray')};
    }

    .file-drop-zone-uploaded {
      background-color: #{map-get($themeColorDef, 'sq-light-gray')};
      border: solid 1px #{map-get($themeColorDef, 'sq-disabled-gray')};
      border-radius: 4px;
    }

    .file-drop-zone-error {
      border: dashed 2px $sq-danger-color !important;
    }

    .unstyled-input {
      color: #{map-get($themeColorDef, 'sq-fairly-dark-gray')};

      &:hover, &:active {
        box-shadow: none !important;
      }
    }

    .colorPickerSwatch {
      &.inactive {
        background-color: #{map-get($themeColorDef, 'sq-disabled-gray')};
      }
    }

    .administration {
      .leftBorder {
        border-left: 1px solid #{map-get($themeColorDef, 'sq-dark-gray')};
      }
    }

    #formulaContainer {
      .card {
        //background-color: ;
        border: 1px solid #{map-get($themeColorDef, 'sq-darkest-gray')} !important;
      }


      .rg-right {
        &:hover {
          background-color: #{map-get($themeColorDef, 'sq-overlay-gray')};
        }
      }

      .rg-bottom {
        &:hover {
          background-color: #{map-get($themeColorDef, 'sq-overlay-gray')};
        }
      }
    }

    .sq-chart {
      .highcharts-cursor-anchor, .highcharts-cursor-y-label, .highcharts-hover-y-label {
        text {
          fill: #{map-get($themeColorDef, 'sq-white')};
        }
      }

      .highcharts-cursor-x-label {
        text {
          fill: $sq-side-panel-text-color;
        }

        stroke: $sq-side-panel-text-color;
      }

      .highcharts-hover-x-label {
        stroke: $sq-side-panel-text-color;
      }
    }

    select:required:invalid, .select-empty {
      color: #{map-get($themeColorDef, 'sq-darkest-gray')};
    }

    option {
      color: $black;
    }

    .worksheetViewSelector {
      .icon {
        color: $sq-white;
      }

      .fa-chevron-down {
        color: #{map-get($themeColorDef, 'sq-dark-gray')};
      }
    }


    .striped {
      background-color: #{map-get($themeColorDef, 'sq-table-highlight')};
    }

    .dl-striped {
      > div:nth-of-type(odd) > dt, > div:nth-of-type(odd) > dd {
        background-color: #{map-get($themeColorDef, '$sq-lightest-gray')};
      }
    }

    .dl-striped-reversed {
      > div:nth-of-type(even) > dt, > div:nth-of-type(even) > dd {
        background-color: #{map-get($themeColorDef, 'sq-table-highlight')};
      }
    }

    .fa.sq-text-warning {
      color: $sq-text-warning-color !important;
    }

    .bg-warning {
      background-color: $backgroundcolor-warn1 !important;
    }

    .bg-warningProgressBar {
      background-color: $sq-status-warning-color
    }

    .sq-text-darkest-gray {
      color: #{map-get($themeColorDef, 'sq-darkest-gray')};
    }

    // Improve the look of blue icons when they are hovered over in the dropdown
    .dropdown-menu > li {
      &:hover .sq-text-primary, a:focus .sq-text-primary {
        color: #{map-get($themeColorDef, 'sq-white')} !important;
      }
    }

    .fa-panel-color {
      color: #{map-get($themeColorDef, 'sq-light-gray')};
    }

    .darkGreyBorder {
      border: 1px solid $sq-darkish-gray;
    }

    .lightGreyBorder {
      border: 1px solid #{map-get($themeColorDef, 'sq-disabled-gray')};
    }

    .lightGreyBorderLeft {
      border-left: 1px solid #{map-get($themeColorDef, 'sq-disabled-gray')};
    }

    .lightGreyBorderRight {
      border-right: 1px solid #{map-get($themeColorDef, 'sq-disabled-gray')};
    }

    .lightGreyBorderTop {
      border-top: 1px solid #{map-get($themeColorDef, 'sq-disabled-gray')};
    }

    .lightGreyBorderBottom {
      border-bottom: 1px solid #{map-get($themeColorDef, 'sq-disabled-gray')};
    }

    .toggleVisibilityBar {
      background: #{map-get($themeColorDef, 'sq-light-gray')};
    }

    .white {
      color: #{map-get($themeColorDef, 'sq-white')} !important;
    }

    .sq-bg-light-gray {
      background-color: #{map-get($themeColorDef, 'sq-light-gray')};
    }

    .sq-bg-dark-gray {
      background-color: #{map-get($themeColorDef, 'sq-dark-gray')};
    }

    .danger-tooltip {
      .tooltip-inner {
        color: #{map-get($themeColorDef, 'sq-white')};
        background-color: $sq-danger-color !important;
        @extend .nowrap;
      }

      &.tooltip.top .tooltip-arrow {
        border-top-color: $sq-danger-color !important;
      }

      &.tooltip.bottom .tooltip-arrow {
        border-bottom-color: $sq-danger-color !important;
      }

      &.tooltip.left .tooltip-arrow {
        border-left-color: $sq-danger-color !important;
      }

      &.tooltip.right .tooltip-arrow {
        border-right-color: $sq-danger-color !important;
      }
    }

    /* Specify styling for tooltips that specify tooltip-class="warningTooltip" */
    .tooltip.warningTooltip {
      .tooltip-inner {
        color: $black;
        background-color: $sq-text-warning-color;
      }
    }

    // Can get this from bootstrap after we upgrade to v4.0
    .alert-secondary {
      color: #{map-get($themeColorDef, 'sq-text-color')};
      background-color: #{map-get($themeColorDef, 'sq-overlay-gray')};
      border-color: #{map-get($themeColorDef, 'sq-disabled-gray')};
    }

    .alert-secondary-hover:hover {
      background-color: (darken#{map-get($themeColorDef, 'sq-overlay-gray')}, 5%);
    }

    .noBackground {
      background-color: transparent !important;
    }

    .text-inactive {
      color: $sq-side-panel-text-color;
    }

    .gray-text, .sq-icon-text {
      color: #{map-get($themeColorDef, 'sq-text-color')};
    }

    .text-color-default {
      color: $text-color;
    }

    .text-white, .sq-icon-white {
      color: $white;
    }

    .sq-icon-gray {
      color: $sq-darkish-gray;
    }

    .text-navbar {
      color: #{map-get($themeColorDef, 'sq-dark-gray')};
    }

    .text-with-shadow {
      text-shadow: 0.5px 0.5px #{map-get($themeColorDef, 'sq-disabled-gray')};
    }

    .uncertain {
      td.endColumn,
      td.startColumn,
      td.durationColumn {
        color: $sq-darkish-gray;
      }
    }

    .overlayPanel {
      background-color: rgba(map-get($themeColorDef, 'sq-white'), 0.93);
      box-shadow: 0 0 2px 2px (darken#{map-get($themeColorDef, 'sq-light-gray')}, 15%);
    }

    .loginFooter {
      background-color: rgba(map-get($themeColorDef, 'sq-fairly-dark-gray'), 0.5);
      color: #{map-get($themeColorDef, 'sq-dark-gray')};
    }

    #scatterPlotFlipXY {
      border-color: #{map-get($themeColorDef, 'sq-disabled-gray')};
      color: #{map-get($themeColorDef, 'sq-text-color')};

      &:hover {
        border-color: (darken#{map-get($themeColorDef, 'sq-dark-gray')}, 15%);
      }
    }

    sq-threshold-metric {
      .colorSwatch {
        border: 1px solid #{map-get($themeColorDef, 'sq-disabled-gray')};
      }
    }

    .toolOverviewCard {
      &:hover, &:focus {
        background-color: #{map-get($themeColorDef, 'sq-light-gray')};
      }
    }

    .dateTimeEntryField {
      background-color: #{map-get($themeColorDef, 'sq-white')};
      border: solid 1px #{map-get($themeColorDef, 'sq-darker-gray')};
    }

    .multiSelectTwoLine {
      .rbt-token {
        color: #{map-get($themeColorDef, 'sq-text-color')};
        background-color: #{map-get($themeColorDef, 'sq-darker-gray')};
        border-color: #cccccc;
      }

      .rbt-input {
        border-color: #{map-get($themeColorDef, 'sq-darker-gray')};
      }

      .dropdown-item {
        &:hover {
          color: #{map-get($themeColorDef, 'sq-text-color')} !important;
          background-color: #{map-get($themeColorDef, 'sq-light-gray')} !important;
        }
      }

      .form-control {
        border: solid 1px #cccccc;
      }
    }

    .multiSelectLarge {
      .rbt-token {
        color: #{map-get($themeColorDef, 'sq-text-color')};
        background-color: #{map-get($themeColorDef, 'sq-darker-gray')};
        border-color: #cccccc;
      }

      .rbt-input {
        border-color: #{map-get($themeColorDef, 'sq-darker-gray')};
      }

      .dropdown-item {
        &:hover {
          color: #{map-get($themeColorDef, 'sq-text-color')} !important;
          background-color: #{map-get($themeColorDef, 'sq-light-gray')} !important;
        }
      }

      .form-control {
        border: solid 1px #cccccc;
      }
    }

    .datasourcesFilterCard {
      border: #{map-get($themeColorDef, 'sq-dark-gray')} solid 1px;
      background-color: #{map-get($themeColorDef, 'sq-white')};
    }

    .datasourceConnectionCard {
      background-color: #{map-get($themeColorDef, 'sq-white')};
    }

    .investigateRangeSelector {
      background-color: #{map-get($themeColorDef, 'sq-white')};
      border-top: solid 1px #{map-get($themeColorDef, 'sq-darker-gray')};
    }

    [content-editable]:hover, [content-editable][contenteditable="true"] {
      color: $black !important;
      background-color: #{map-get($themeColorDef, 'sq-overlay-gray')} !important;
    }

    .investigateRangeSelector, .displayRangeSelector {
      sq-date-time-entry, .displayRangeDateTime, .durationTimeEntry {
        .readOnly {
          color: $sq-side-panel-text-color;
        }
      }
    }

    .requestDetailsTitle {
      color: #{map-get($themeColorDef, 'sq-text-color')};
    }

    .requestDetailsLegendSwatch {
      box-shadow: 0 0 3px (darken#{map-get($themeColorDef, 'sq-overlay-gray')}, 15%);
    }

    .folderExplorerRow {
      &:hover {
        background-color: #{map-get($themeColorDef, 'sq-light-gray')};
      }
    }

    .dropdown-menu > li > a, .dropdown-item {
      &:hover,
      &:focus {
        color: #{map-get($themeColorDef, 'sq-white')} !important;
      }
    }

    .mainHeader {
      .sq-navbar-default {
        .sq-navbar-noninteractable {
          color: #{map-get($themeColorDef, 'sq-dark-gray')};
        }
      }

      .navbarHomeButton {
        color: $sq-white;

        &:active {
          color: $sq-white;
        }
      }
    }


    header h4, header h5 {
      color: #{map-get($themeColorDef, 'sq-white')};
    }

    .headerOptionsButton {
      border-left: $sq-light-thin-border;
    }

    #dataSourcesPanel {
      background-color: #{map-get($themeColorDef, 'sq-light-gray')};

      .flexRowContainer {
        border-bottom: 1px solid #{map-get($themeColorDef, 'sq-darkest-gray')};
      }
    }

    .mainFooter {
      background-color: #{map-get($themeColorDef, 'sq-footer-color')};
      border-top: 1px solid #{map-get($themeColorDef, 'sq-darkest-gray')};
    }

    .input-div {
      background-color: #{map-get($themeColorDef, 'sq-white')};
    }

    .btn-transparent {
      &:hover, &:active {
        color: #{map-get($themeColorDef, 'sq-white')} !important;
      }
    }

    .editableText {
      &:hover {
        background: #{map-get($themeColorDef, 'sq-overlay-gray')};
        color: #{map-get($themeColorDef, 'sq-text-color')};
      }
    }

    .editableTextInput {
      background: #{map-get($themeColorDef, 'sq-overlay-gray')};
      color: #{map-get($themeColorDef, 'sq-text-color')};
    }

    .tableBuilderHelpText {
      .coloredAnalysis {
        color: $sq-analysis-color;
      }

      .coloredReport {
        color: $sq-icon-over-topic;
      }
    }

    .bottomPanel {
      .panelBorderRight {
        border-right: 1px solid #{map-get($themeColorDef, 'sq-lightest-gray')};
      }

      .panelBorderLeft {
        border-left: 1px solid #{map-get($themeColorDef, 'sq-lightest-gray')};
      }

      .divider {
        i {
          color: grey;
        }
      }

      .header {
        color: #{map-get($themeColorDef, 'sq-text-color')};
        background-color: #{map-get($themeColorDef, 'sq-table-highlight')};
      }

      .table thead th {
        border-bottom: #{map-get($themeColorDef, 'sq-lightest-gray')} 1px solid;
      }
    }

    .workbookAcl {
      ul.dropdown-menu {

        li.active > a {
          &, &:hover, &:focus {
            color: #{map-get($themeColorDef, 'sq-white')};
          }
        }
      }
    }

    .newWorkbookLabel {
      color: #{map-get($themeColorDef, 'sq-white')};
    }

    .searchNavigation {
      background-color: $backgroundcolor-block;

      .sq-fairly-dark-gray {
        color: darken($backgroundcolor-block, 30%);
      }
    }

    .screenshotBackground {
      background-color: #{map-get($themeColorDef, 'sq-white')};
    }

    .workbookSlide {
      &:hover {
        box-shadow: 0 0 6px (darken#{map-get($themeColorDef, 'sq-light-gray')}, 15%);
      }

      &.text-center:hover {
        box-shadow: none;
      }

      &.active {
        border-left: 8px solid $sq-active-color;
        background: #{map-get($themeColorDef, 'sq-light-gray')};
      }
    }

    .worksheetViewSelector {
      .icon {
        color: $sq-white;
      }

      .fa-chevron-down {
        color: #{map-get($themeColorDef, 'sq-dark-gray')};
      }
    }

    .itemRowSelected {
      border-color: $bordercolor-hover !important;
      background-color: $backgroundcolor-hover !important;
    }

    .itemRow {
      border-bottom: 1px solid  #{map-get($themeColorDef, 'border-color')};
      color: #{map-get($themeColorDef, 'sq-text-color')};

      &:hover {
        background-color: #{map-get($themeColorDef, 'backgroundcolor-table-highlight-green')} !important;
      }

      .searchBtn {
        &.fc-pin {
          @extend .sq-text-primary;
        }

        &:hover:not(.fa-spin), &:focus:not(.fa-spin) {
          @extend .sq-text-primary;
        }
      }
    }

    .disabledItemRow {
      &:hover, &:focus {
        background-color: transparent !important;
        border-bottom: 1px solid #{map-get($themeColorDef, 'sq-darkest-gray')} !important;
      }
    }

    sq-new-workbench-item {
      .cursorPointer:hover {
        background-color: $backgroundcolor-selected;
      }

      .borderTop {
        border-top: 1px solid #{map-get($themeColorDef, 'sq-disabled-gray')};
      }
    }

    .toolsSearchBar {
      border-bottom: 1px solid #{map-get($themeColorDef, 'sq-darker-gray')};
    }


    .tabOutline {
      border-left: 1px solid #{map-get($themeColorDef, 'sq-dark-gray')};
      border-right: 1px solid #{map-get($themeColorDef, 'sq-dark-gray')};
      border-bottom: 1px solid #{map-get($themeColorDef, 'sq-dark-gray')};
      padding-top: 10px;
    }

    /******************* Report Editor *******************/
    .reportDateModal {
      .displayRangeSelector {

        a {
          color: $black;
        }

        sq-date-time-entry, .displayRangeDateTime, .durationTimeEntry {
          color: $black;
        }

        div > sq-date-time-entry > div,
        div > .displayRangeDateTime > div {
          border: 1px solid #{map-get($themeColorDef, 'sq-darker-gray')};
        }

        div > sq-date-time-entry.readOnly > div,
        div > .displayRangeDateTime.readOnly > div {
          border: 1px solid #{map-get($themeColorDef, 'sq-darker-gray')};
        }

        #displayRangeNavigation > .durationTimeEntry {
          border: 1px solid #{map-get($themeColorDef, 'sq-darkest-gray')};
        }

        #conditionEditor {
          ul.dropdown-menu {
            li.active > a {
              &, &:hover, &:focus {
                color: #{map-get($themeColorDef, 'sq-white')};
              }
            }
          }
        }
      }

      .hover-text-muted-more {
        color: #{map-get($themeColorDef, 'sq-disabled-gray')};
      }

      .card-primary {
        border-color: #{map-get($themeColorDef, 'sq-overlay-gray')};
      }
    }

    .searchWidget {
      .heading {
        border-bottom: 1.5px solid #{map-get($themeColorDef, 'sq-darkest-gray')};
      }
    }

    .administration {
      .leftBorder {
        border-left: 1px solid #{map-get($themeColorDef, 'sq-dark-gray')};
      }
    }

    .selectedFolderExplorerRow {
      background-color: #{map-get($themeColorDef, 'sq-light-gray')};
    }

    .textPrimaryChartSettings {
      &:hover, &:active {
        &, & > a {
          color: $sq-dark-primary-analysis;
          background-color: #{map-get($themeColorDef, 'sq-light-gray')};
        }
      }
    }

    .textPrimaryMenuItem {
      &:hover {
        &, & > a {
          //color: #{map-get($themeColorDef, 'sq-white')} !important;
          background-color: #{map-get($themeColorDef, 'sq-light-gray')};
        }
      }

      &.dropright > a {
        color: #{map-get($themeColorDef, 'sq-text-color')};
      }
    }

    .dropdown-menu > .active > a {
      &:hover {
        color: #{map-get($themeColorDef, 'sq-white')} !important;
      }
    }

    ul.searchBreadcrumbs {
      li:last-child, .title {
        color: #{map-get($themeColorDef, 'sq-fairly-dark-gray')} !important;
      }
    }

    .rotateDrag {
      transform: rotate(90deg);
    }

    .browseColumnCollapsed {
      background-color: $sq-backdrop;
      border: 1px solid #{map-get($themeColorDef, 'sq-darkest-gray')};
    }

    .dividerBarIconWrapper {
      &:hover {
        .dividerBarIconBg {
          i {
            color: white !important
          }
        }
      }
    }

    .dividerBarIconWrapperRight {
      &:hover {
        .dividerBarIconBg {
          i {
            color: white !important
          }
        }
      }
    }

    .browseColumnCollapsed {
      &:hover {
        .dividerBarIconBg {
          i {
            color: white !important
          }
        }
      }
    }

    .dividerBarIconBg {
      border: 1px solid #{map-get($themeColorDef, 'sq-darkest-gray')};
      height: 25px;
      width: 25px;
      border-radius: 100%;
      background-color: #{map-get($themeColorDef, 'sq-white')};
    }


    .toolbar-sm {
      border-color: (darken#{map-get($themeColorDef, 'sq-dark-gray')}, 10%);
    }

    .optionHeader {
      background: #{map-get($themeColorDef, 'sq-light-gray')};
    }

    .btn-toolbar {
      color: #{map-get($themeColorDef, 'sq-text-color')} !important;

      & .active {
        background: (darken#{map-get($themeColorDef, 'sq-light-gray')}, 5%);
      }
    }

    .searchTitleInput {
      & input {
        &:focus {
          color: #{map-get($themeColorDef, 'sq-text-color')};
        }
      }
    }

    .worksheetViewSelector {

      .fa-chevron-down {
        //color: #{map-get($themeColorDef, 'sq-dark-gray')};
        color: $sq-white;
      }
    }

    .table-white {
      background: #{map-get($themeColorDef, 'sq-white')};
    }

    .fixedHeaderTable {
      th {
        background: #{map-get($themeColorDef, 'sq-white')};
      }
    }

    /* workbooks */
    .workbookSlide {
      box-shadow: 0 0 6px #{map-get($themeColorDef, 'sq-white')};
    }

    #regionSlider {
      .selecting circle.selected {
        stroke: $red;
      }

      .resize path {
        fill: #{map-get($themeColorDef, 'sq-darkest-gray')};
        stroke: #{map-get($themeColorDef, 'sq-text-color')};
      }

      .axis path, .axis line {
        stroke: $black;
      }

      .brush .extent {
        fill: #797BFC;
      }
    }

    #formulaEditorToolbar {
      border: 1px solid #{map-get($themeColorDef, 'sq-overlay-gray')};
      box-shadow: 0 1px 3px rgba($black, 0.12), 0 1px 1px 1px rgba($black, 0.16);
    }

    .input-div {
      border: 1px solid #{map-get($themeColorDef, 'sq-disabled-gray')};
    }

    .workbenchBtn {
      @extend .sq-btn-theme;
      color: #{map-get($themeColorDef, 'sq-white')};

      &:hover, &:active, &:focus {
        color: #{map-get($themeColorDef, 'sq-white')} !important;
      }
    }

    .form-control {
      outline: 0 !important;
    }

    .dropdown-menu > .active > a {

      &:hover {
        color: #{map-get($themeColorDef, 'sq-white')} !important;
      }
    }

    .btn-stat {
      background: #{map-get($themeColorDef, 'sq-white')};

      &:hover {
        border: 1px solid #{map-get($themeColorDef, 'sq-disabled-gray')};
        background: #{map-get($themeColorDef, 'sq-white')};
        border-radius: 2px;
      }
    }

    .btn-stat-divider {
      padding-right: 6px;
      border-right: 1px solid #{map-get($themeColorDef, 'sq-disabled-gray')};
      margin-right: 6px;
    }

    [content-editable]:hover, [content-editable][contenteditable="true"] {
      background-color: #{map-get($themeColorDef, 'sq-overlay-gray')} !important;
    }

    .modal-body .optionSelected {
      color: #{map-get($themeColorDef, 'sq-white')};
    }

    .redactionBannerReload {
      color: lighten($sq-text-warning-color, 25%);

      &:hover {
        color: #8a6d3b;
      }
    }

    .homeScreenRightPanel {
      width: 400px;
      padding: 10px 20px 80px;
      background-color: #{map-get($themeColorDef, 'home-screen-gray')};
    }

    .homeScreenLeftPanel {
      border-right-color: #{map-get($themeColorDef, 'sq-dark-gray')};
    }

    .homeScreenNav {
      padding: 10px;
      font-size: 15px;
      margin-bottom: 5px;
      margin-right: 8px;
      background-color: #{map-get($themeColorDef, 'home-screen-gray')};
    }

    .homeScreenNotification {
      padding: 20px;
      background-color: #{map-get($themeColorDef, 'sq-white')};
      border: 1px solid #{map-get($themeColorDef, 'sq-dark-gray')};
    }

    .homeScreenNoContent {
      @extend h4;
      color: #{map-get($themeColorDef, 'sq-darkest-gray')};
    }

    .newBtnWorkbench {
      .cursorPointer:hover {
        background-color: $backgroundcolor-selected;
      }
    }

    .homeScreenWorksheetContainer {
      &:hover {
        background: #{map-get($themeColorDef, 'sq-light-gray')};
      }
    }

    .hoverTable > tr:hover {
      background-color: #{map-get($themeColorDef, 'hover-gray')} !important;
    }

    .newHomeScreenItem {
      &:hover {
        background-color: #{map-get($themeColorDef, 'hover-gray')};
      }
    }

    .newHomeScreenItemWrapper {
      .dropdown-menu > a:hover {
        background-color: #{map-get($themeColorDef, 'hover-gray')} !important;
      }
    }

    .colorGray {
      color: #{map-get($themeColorDef, 'sq-disabled-gray')};
    }

    .input-group-append {
      .advanced-options {
        @extend .noBorder;
        background-color: #{map-get($themeColorDef, 'sq-white')};
      }

      .search-icon {
        border: none;
        border-left: 1px solid #{map-get($themeColorDef, 'sq-disabled-gray')};
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
        background-color: #{map-get($themeColorDef, 'sq-light-gray')};
      }
    }

    .paginatorWrapper {
      border-top: 1px solid #{map-get($themeColorDef, 'sq-dark-gray')};
    }

    .pagination > .active > a {
      &:hover, &:focus {
        background-color: #{map-get($themeColorDef, 'sq-dark-gray')};
        color: $text-color;
        border-color: #{map-get($themeColorDef, 'sq-dark-gray')};
      }

      background-color: #{map-get($themeColorDef, 'sq-dark-gray')};
      color: $text-color;
      border-color: #{map-get($themeColorDef, 'sq-dark-gray')};
    }

    .pagination > li > a, .pagination > li > span {
      color: $text-color;
    }

    pre.CodeMirror-placeholder {
      color: #777777 !important;
    }

    .btn-transparent.sq-text-danger {
      color: $sq-danger-color !important;
    }

    .dropdown {
      .dropdown-item:not(.disabled) {
        color: #{map-get($themeColorDef, 'sq-text-color')};

        &:active, &:hover, &:focus {
          color: #{map-get($themeColorDef, 'sq-white')} !important;
        }
      }
    }

    .sq-slider::-moz-range-track {
      background-color: #{map-get($themeColorDef, 'sq-dark-gray')};
    }

    .fakeTableStriped:nth-of-type(odd) {
      background-color: #{map-get($themeColorDef, 'sq-footer-color')};
    }

    .documentBackground {
      background: #{map-get($themeColorDef, 'sq-light-gray')};
    }

    .documentContent {
      background: transparent;
    }

    .sq-btn-danger {
      color: #{map-get($themeColorDef, 'sq-white')};
      background-color: $sq-danger-color;
      border-color: $sq-danger-color;

      &:active, &:active:focus, &:active:hover {
        background-color: darken($sq-danger-color, 10%);
      }
    }

    .sq-btn-theme-light {
      color: #{map-get($themeColorDef, 'sq-white')};
    }

    /* IE*/
    .sq-slider::-ms-fill-upper {
      background-color: #{map-get($themeColorDef, 'sq-dark-gray')};
    }

    .errorTooltip {
      .arrow::before {
        border-top-color: $sq-danger-color !important;
      }

      .tooltip-inner {
        background-color: $sq-danger-color;
      }
    }

    .sampleInput {
      background-color: #{map-get($themeColorDef, 'sq-light-gray')};
      border: 1px solid $sq-darkish-gray;
    }

    .sq-btn-organizer-migration {
      $migration-color: #00899F;
      color: #{map-get($themeColorDef, 'sq-white')};
      border-color: $migration-color;
      background-color: $migration-color;

      &:focus, &:hover {
        border-color: lighten($migration-color, 3%);
        background-color: lighten($migration-color, 3%);
      }

      &:active, &:active:focus, &:active:hover {
        background-color: lighten ($migration-color, 6%);
        border-color: darken($migration-color, 3%);
      }
    }

    .homeScreenWelcomeTile {
      background-color: #{map-get($themeColorDef, 'sq-light-gray')};
      border: 1px solid $sq-darkish-gray;
    }

    .tourCarousel {
      .carousel-indicators {
        li:not(.active) {
          background-color: #{map-get($themeColorDef, 'sq-disabled-gray')};
        }
      }
    }

    .blackout {
      background-color: rgba(0, 0, 0, 0.6);
    }

    .noCoverInBlackout {
      background-color: inherit;
    }

    .hoverGray:hover {
      color: $sq-darkish-gray;
    }

    .formula-border {
      border: 1px solid $sq-darkish-gray;
    }

    .formulaExample {
      .CodeMirror {
        background-color: #{map-get($themeColorDef, 'sq-light-gray')};
        color: #{map-get($themeColorDef, 'sq-text-color')};
        border: 1px solid $sq-darkish-gray;
      }
    }

    figure.seeqTableStriped > table > tbody > tr:nth-of-type(even) {
      background: whitesmoke;
    }

    .CircularProgressbar-trail {
      stroke: #{map-get($themeColorDef, 'sq-disabled-gray')} !important;
    }

    .CircularProgressbar-text {
      fill: #{map-get($themeColorDef, 'sq-text-color')} !important;
    }

    .sqGrayBox {
      @extend .borderGray;
      @extend .sq-bg-light-gray;
    }

    .tableViewer {
      background: #{map-get($themeColorDef, 'sq-white')};

      &.customOverlay {
        border: 1px solid #{map-get($themeColorDef, 'sq-dark-gray')};
      }

      .header {
        background-color: $sq-link-color-analysis;
        color: #{map-get($themeColorDef, 'sq-white')};
      }
    }

    .form-control:invalid, .form-control.is-invalid {
      border-color: $sq-danger-color !important;
    }

    .pagination {
      .page-link {
        background-color: #{map-get($themeColorDef, 'sq-white')};
      }

      .page-item.active {
        .page-link {
          &, &:hover, &:focus {
            color: #{map-get($themeColorDef, 'sq-white')} !important;
          }
        }
      }
    }

    .tableRowAccent {
      background-color: #{map-get($themeColorDef, 'sq-table-highlight')} !important;
    }

    .dividerBg {
      background-color: #{map-get($themeColorDef, 'sq-darkest-gray')};
      overflow: hidden;
    }

    .dividerBorderTopBottom {
      border-top: 1px solid #{map-get($themeColorDef, 'sq-lightest-gray')};
      border-bottom: 1px solid #{map-get($themeColorDef, 'sq-lightest-gray')};
    }


    .sq-text-warning, .sq-icon-warning {
      color: #{map-get($themeColorDef, 'sq-text-warning-color')};
    }

    .sq-dark-gray {
      color: #{map-get($themeColorDef, 'sq-dark-gray')};
    }

    .splashScreenLight {
      background-color: #{map-get($themeColorDef, 'sq-light-gray')};
    }

    .worksheetsColumn {
      background-color: #{map-get($themeColorDef, 'sq-worksheetspanel-gray')};
      color: #{map-get($themeColorDef, 'sq-text-color')};
    }

    .alert-info {
      background-color: rgba(207, 201, 202, 0.1);
      color: #{map-get($themeColorDef, 'sq-text-color')};
    }

    .toolbar {
      color: #{map-get($themeColorDef, 'sq-text-color')};
      background-color: #{map-get($themeColorDef, 'sq-white')};
      border-bottom: 2px solid  #{map-get($themeColorDef, 'sq-darkest-gray')};

      .btn-group:not(.dropdown) {
        border-right: 1px solid  #{map-get($themeColorDef, 'sq-darkest-gray')};
      }
    }


    .browseColumn {
      background-color: #{map-get($themeColorDef, 'sq-backdrop')};
      border-left: 3px solid  #{map-get($themeColorDef, 'sq-darkest-gray')};
      border-right: 3px solid #{map-get($themeColorDef, 'sq-darkest-gray')};
      color: #{map-get($themeColorDef, 'sq-text-color')};

      .toolDescription {
        color: #{map-get($themeColorDef, 'sq-text-color')};
      }

      .toolbar {
        background: #{map-get($themeColorDef, 'sq-darkest-gray')};

        .btn-default {
          background: #{map-get($themeColorDef, 'sq-white')};
        }

        .option .btn .active {
          border-color: #{map-get($themeColorDef, 'sq-active-color')};
          color: #{map-get($themeColorDef, 'sq-darker-gray')};
        }
      }

      .toolOverviewCard {
        border-bottom: 1px solid #{map-get($themeColorDef, 'border-color')};

        &:hover, &:focus {
          background-color: #{map-get($themeColorDef, 'hover-gray')};

          .toolDescription {
            color: #{map-get($themeColorDef, 'sq-fairly-dark-gray')};
          }
        }
      }

      .nav-tabs .nav-link.active {
        color: #{map-get($themeColorDef, 'sq-text-color')};
      }

      .nav-tabs {
        background: #{map-get($themeColorDef, 'sq-darkest-gray')};
        border-color: #{map-get($themeColorDef, 'sq-darkest-gray')};
      }

      .nav-tabs.nav-justified > li {
        background-color: #{map-get($themeColorDef, 'sq-darker-gray')};

        & > a {
          color: #{map-get($themeColorDef, 'sq-black')};
          border-color: (darken#{map-get($themeColorDef, 'sq-dark-gray')}, 5%);
        }

        &.active {
          & > a {
            &, &:hover, &:focus {
              background-color: #{map-get($themeColorDef, 'sq-light-gray')};
            }
          }
        }

        &:not(.disabled):not(.active) {
          & > a {
            &:hover, &:focus {
              background-color: #{map-get($themeColorDef, 'sq-overlay-gray')};
            }
          }
        }
      }

      .card.card-default {
        color: #{map-get($themeColorDef, 'sq-text-color')};
        background-color: #{map-get($themeColorDef, 'sq-white')};

        hr {
          border-color: #{map-get($themeColorDef, 'sq-dark-gray')};
        }

        .card-header, .card-body {
          background-color: #{map-get($themeColorDef, 'sq-white')};
        }
      }

      .card.card-primary {
        .card-header, .card-body {
          background-color: #{map-get($themeColorDef, 'sq-tools-background')} !important;
        }
      }

      .card-primary {
        backround-color: #{map-get($themeColorDef, 'sq-tools-background')} !important;
        border-color: #{map-get($themeColorDef, 'sq-dark-gray')} !important;
      }
    }

    .panelTabs {
      a {
        &.active {
          background-color: #{map-get($themeColorDef, 'sq-white')} !important;
        }

        &:hover {
          background-color: #{map-get($themeColorDef, 'sq-lightest-gray')};
          border-color: #{map-get($themeColorDef, 'sq-dark-gray')};
        }

        &.disabled {
          background-color: #{map-get($themeColorDef, 'sq-disabled-gray')} !important;
        }

        background-color: #{map-get($themeColorDef, 'sq-white')};
        border-color: #{map-get($themeColorDef, 'sq-dark-gray')} !important;
      }

    }

    .card {
      background-color: #{map-get($themeColorDef, 'sq-tools-background')} !important;
      color: #{map-get($themeColorDef, 'sq-text-color')} !important;
    }

    .dropdown-menu {
      background-color: #{map-get($themeColorDef, 'sq-tools-background')} !important;
      color: #{map-get($themeColorDef, 'sq-text-color')} !important;
    }

    .form-control {
      background-color: #{map-get($themeColorDef, 'sq-tools-background')} !important;
      color: #{map-get($themeColorDef, 'sq-text-color')};
      border-color: #{map-get($themeColorDef, 'border-color')};
    }

    .table {
      color: #{map-get($themeColorDef, 'sq-text-color')};

      thead th {
        border-bottom: #{map-get($themeColorDef, 'sq-lightest-gray')} 1px solid;
        border-top: #{map-get($themeColorDef, 'sq-lightest-gray')} 1px solid;
      }
    }

    .btn {
      color: #{map-get($themeColorDef, 'sq-text-color')};
    }

    .timebarWrapper {
      .outer {
        border: 2px solid  #{map-get($themeColorDef, 'sq-disabled-gray')};
        background-color: #{map-get($themeColorDef, 'sq-white')};
      }

      .leftSelector,
      .rightSelector {
        background-color: #{map-get($themeColorDef, 'sq-white')};
      }

      .ticksContainer {

        .highcharts-axis-labels {
          text {
            fill: #{map-get($themeColorDef, 'sq-gray-text')} !important;
          }
        }
      }

      .timebarContainer {
        .selector {
          background-color: #{map-get($themeColorDef, 'sq-white')};
        }
      }
    }

    #detailsPanelContent {
      tbody {

        tr {
          background-color: #{map-get($themeColorDef, 'sq-white')};
        }
      }
    }

    .selectedTableRow {
      background-color: #{map-get($themeColorDef, 'backgroundcolor-table-highlight-green')} !important;
    }

    .table-striped tbody tr:nth-of-type(odd):not(.selectedTableRow) {
      background-color: #{map-get($themeColorDef, 'sq-table-highlight')} !important;
    }

    .input-group-text {
      color: #{map-get($themeColorDef, 'sq-text-color')};
      background-color: #{map-get($themeColorDef, 'sq-tools-background')} !important;
      border: 1px solid #{map-get($themeColorDef, 'border-color')};
    }

    .CodeMirror {
      background-color: #{map-get($themeColorDef, 'sq-tools-background')} !important;
      color: #{map-get($themeColorDef, 'sq-text-color')};
    }

    .modal-content {
      background-color: #{map-get($themeColorDef, 'sq-tools-background')};
      color: #{map-get($themeColorDef, 'sq-text-color')};
    }


    .popover {
      background-color: #{map-get($themeColorDef, 'sq-tools-background')} !important;
      color: #{map-get($themeColorDef, 'sq-text-color')} !important;
    }

    .popover-body {
      color: #{map-get($themeColorDef, 'sq-text-color')} !important;
    }

    .popover-header {
      background-color: #{map-get($themeColorDef, 'sq-worksheetspanel-gray')} !important;
      color: #{map-get($themeColorDef, 'sq-text-color')} !important;

    }

    .datasourcesPopup, .autoUpdatePopup {
      .arrow {
        background-color: #{map-get($themeColorDef, 'sq-tools-background')} !important;
      }
    }


    .dropdown-divider {
      border-top-color: #{map-get($themeColorDef, 'sq-darkest-gray')}
    }

    .close {
      color: #{map-get($themeColorDef, 'sq-text-color')};
    }

    .modal-header {
      border-bottom: 1px solid  #{map-get($themeColorDef, 'border-color')};
    }

    .modal-footer {
      border-top: 1px solid  #{map-get($themeColorDef, 'border-color')};
    }

    .nav-tabs {
      border-bottom: 1px solid #{map-get($themeColorDef, 'border-color')};
    }

    .nav-tabs .nav-link.active {
      color: #{map-get($themeColorDef, 'sq-text-color')};
      background-color: #{map-get($themeColorDef, 'sq-footer-color')};
      border-color: #{map-get($themeColorDef, 'border-color')} !important;
    }

    .fakeTableRowDivider {
      border-top: 1px solid #{map-get($themeColorDef, 'border-color')};
      border-bottom: 3px solid  #{map-get($themeColorDef, 'border-color')};
    }

    .fakeTableRow {
      border-bottom: 1px solid #{map-get($themeColorDef, 'border-color')};
    }

    .fakeTableHeader {
      border-bottom: 2px solid #{map-get($themeColorDef, 'border-color')};
    }

    .fakeTable .flexRowContainer:nth-of-type(odd) {
      background-color: #{map-get($themeColorDef, 'sq-footer-color')};
    }

    .logFiltersContainer {
      background-color: #{map-get($themeColorDef, 'sq-footer-color')};
    }

    .sq-bg-trace:nth-child(even) {
      background: #{map-get($themeColorDef, 'backgroundcolor-trace1')};
    }

    .sq-bg-trace:nth-child(odd) {
      background: #{map-get($themeColorDef, '$backgroundcolor-trace2')};
    }

    .sq-bg-debug:nth-child(even) {
      background: #{map-get($themeColorDef, '$backgroundcolor-debug1')};
    }

    .sq-bg-debug:nth-child(odd) {
      background: #{map-get($themeColorDef, '$backgroundcolor-debug2')};
    }

    // Empty styling reserved to enable easy class assignment and reserving this as an un-modified class.
    .sq-bg-info:nth-child(even) {
      background: #{map-get($themeColorDef, 'sq-white')};
    }

    .sq-bg-info:nth-child(odd) {
      background: #{map-get($themeColorDef, 'backgroundcolor-info2')};
    }

    .sq-bg-warn:nth-child(even) {
      background: #{map-get($themeColorDef, 'backgroundcolor-warn1')};
    }

    .sq-bg-warn:nth-child(odd) {
      background: #{map-get($themeColorDef, 'backgroundcolor-warn2')};
    }

    .sq-bg-error:nth-child(even) {
      background: #{map-get($themeColorDef, 'backgroundcolor-error1')};
    }

    .sq-bg-error:nth-child(odd) {
      background: #{map-get($themeColorDef, 'backgroundcolor-error2')};
    }

    .contentWrapper {
      background: #{map-get($themeColorDef, 'sq-background')};
    }

    .modalSeparation {
      h5 span {
        background: #{map-get($themeColorDef, 'sq-white')};
      }
    }

    textarea {
      background-color: #{map-get($themeColorDef, 'sq-background')};
    }

    .treemapPage {
      .breadcrumb {
        background-color: #{map-get($themeColorDef, 'sq-table-highlight')};
      }
    }

    .advancedPopover, .advancedPopoverAdvancedSearch {
      background-color: #{map-get($themeColorDef, 'sq-white')};
    }

    color: #{map-get($themeColorDef, 'sq-text-color')};
    background-color: #{map-get($themeColorDef, 'sq-background')};
  }
}

.alwaysWhite {
  color: #fff !important;
}
