/*
 * Configure the z-index of the editor UI, so when inside a Bootstrap
 * modal, it will be rendered over the modal.
 * See: https://ckeditor.com/docs/ckeditor5/latest/installation/frameworks/css.html
 */
:root {
  --ck-z-default: 100 !important;

  .tw-dark {
    --ck-custom-white: #000;
    --ck-custom-background: rgb(36, 37, 37);
    --ck-custom-foreground: rgb(194, 188, 176);
    --ck-color-base-foreground: rgb(194, 188, 176);
    --ck-color-button-default-background: rgb(36, 37, 37);
    --ck-color-text: rgb(194, 188, 176);
    --ck-color-list-background: rgb(36, 37, 37);
    --ck-color-dropdown-panel-background: rgb(36, 37, 37);
    --ck-color-button-on-background: rgb(36, 45, 49);
    --ck-color-list-button-on-background: rgb(36, 45, 49);
    --ck-color-toolbar-background: rgb(36, 37, 37);
    --ck-color-button-default-hover-background: rgb(36, 45, 49);
    --ck-color-button-default-active-background: rgb(36, 45, 49);
    --ck-color-button-on-active-background: rgb(36, 45, 49);
    --ck-color-button-on-hover-background: rgb(36, 45, 49);
    --ck-color-split-button-hover-background: rgb(36, 45, 49);
    --ck-color-toolbar-border: rgb(65, 67, 68);
    --ck-color-panel-background: rgb(36, 37, 37);
    --ck-color-input-background: #000;
    --ck-color-input-disabled-background: rgb(36, 37, 37);
    --ck-color-labeled-field-label-background: rgb(36, 37, 37);
  }
}

.ckEditorMain .reportEditorWrapper, .editor {
  &.journalOnly {
    overflow: inherit;
  }

  .flexRowContainer.flexFill.flexBasisZero .ck-editor__editable {
    width: 100%;
    min-height: 100%;
    padding: 16px;
    margin: 0px;
  }

  .editorContainer {
    background: transparent;
    overflow: auto;
    height: 100%;

    // Tables aligned left or right via float should be cleared by headings
    figure.table.ck-widget, p, h1, h2, h3, h3, h4, h5, h6 {
      clear: both;
    }
  }

  .ck-content {
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
    min-height: 100%;
  }

  // Base Seeq customizations for the CK editor.
  .ck-toolbar.ck-rounded-corners {
    height: 35px; // aligns with the tab panel
    // disable the thick border and rounded corners on the top of the editor
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-width: 2px;
    border-radius: 0;
    position: relative;

    .ck.ck-toolbar__grouped-dropdown:last-child {
      position: initial;
    }

    .ck-dropdown__panel.ck-dropdown__panel_sw.ck-dropdown__panel-visible {
      width: auto;
    }

    .ck.ck-dropdown.ck-heading-dropdown .ck-dropdown__button .ck-button__label {
      width: 85px;
    }

    .ck-toolbar__items :not(.ck-toolbar__line-break),
    .ck.ck-toolbar__grouped-dropdown {
      margin-top: 0;
      margin-bottom: 0;

      .ck-button {
        &.ck-dropdown__button.contentBtn {
          min-height: 24px;
          min-width: 24px;
          padding: 0 6px;

          svg {
            height: 14px;
            width: 14px;
            margin: 0;
          }
        }

        i, svg.ck-icon {
          height: 22px;
        }
      }
    }
  }
}


.ckEditorMain.fixedWidth .reportEditorWrapper {
  @media screen {
    .editorContainer {
      padding: 40px 0;
      height: 100%;
      background-color: #fafafa;

      .ck-content {
        background: white;
        border-radius: var(--ck-border-radius);
        border: 1px #D3D3D3 solid;
        margin: 0 auto;
        min-height: 11in;
        overflow-x: visible;
        overflow: visible;
        padding: .5in;
        width: 8.5in;

        div.page-break {
          background: #fafafa;
          border-bottom: 1px #D3D3D3 solid;
          border-top: 1px #D3D3D3 solid;
          left: calc(-0.5in - 1px);
          margin: 40px 0;
          width: 8.5in;
        }

        &.ck-focused {
          border-color: #333333;
          box-shadow: none;

          div.page-break {
            border-color: #333333;
          }
        }
      }
    }
  }

  @media print {
    .editorContainer {
      background: $white;
      color: $sq-text-color;
    }
  }
}

.tw-dark {
  .ckEditorMain.fixedWidth .reportEditorWrapper {
    @media screen {
      .editorContainer {
        background-color: $black;

        .ck-content {
          background: $sq-tools-background_dark;

          div.page-break {
            background: $black;
          }
        }
      }
    }
  }
}


.editorBtn {
  height: 30px;
  padding: 4px;
  width: 30px;
  margin: 2px;
  border-radius: 5px;

  &.contentBtn {
    background-color: $sq-link-color-topic !important;
    color: white;
  }

  &:hover {
    background-color: $sq-dark-gray;
  }
}

.editorToolbarContainer > div > div.ck.ck-toolbar__items > div.ck.ck-dropdown.ckEditorDropdownButton > button {
  color: white !important;

  &:hover, &:active {
    background-color: $sq-link-color-analysis !important;
  }
}

.ck.ck-button.contentBtn {
  background-color: $sq-link-color-analysis !important; // always use analysis color
  color: white !important;
  border-radius: 5px !important;

  .ck.ck-icon.ck-button__icon {
    color: white !important;
  }

  &:hover {
    cursor: pointer;
  }
}

.ckEditorDropdownButton .ck-dropdown__panel {
  border-radius: 6px !important;
}

.contentWrapper {
  text-decoration: none;

  &.noCopy {
    // CK treats copying a piece of content and its text as copying two instances of the content
    user-select: none;
  }

  table {
    margin: 0 !important;
  }

  td.nowrap {
    white-space: normal;
  }

  // Ensure that the icon for errored and loading content can always be seen, even if content cannot load.
  .minimumContentSize {
    min-width: 30px; // size of the icons that are used.
    min-height: 30px;
  }

  .maximumHeightAndWidth {
    width: 100%;
    height: 100%;
  }
}

.inlineBlock.seeqContentWrapper.ck-widget {
  vertical-align: bottom;

  img.contentLoadError {
    min-width: 50px;
  }
}

.seeqContentWrapper.tableContainer {
  min-width: 30px;
  min-height: 30px;
  width: auto;
}

.dateRangePopover .arrow {
  display: none;
}

.ck-tooltip {
  z-index: 10000 !important;
}

.ck.ck-editor__editable.ck-read-only .ck-widget.ck-widget_selected, .ck.ck-editor__editable.ck-read-only .ck-widget.ck-widget_selected:hover {
  --ck-widget-outline-thickness: 0px;
}

.ck.ck-button.ck-button_with-text.codePreformat span.ck.ck-button__label {
  font-family: "SFMono-Regular", "Menlo", "Monaco", "Consolas", "Liberation Mono", "Courier New", "monospace";
}

.resizableJournalToolPanel {
  z-index: 101; // to be higher then the minimap and details panel toolbar z-index
}

.ck.ck-editor__editable.ck-read-only {
  .ck-widget_with-selection-handle,
  .ck-widget_with-selection-handle:hover {
    .ck-widget__selection-handle {
      display: none;
    }
  }
}

.reportEditor .ck-content .table table {
  td {
    padding: 2px 5px;

    p {
      margin-bottom: 0;
    }
  }
}

.classicEditor {
  .ck-content {
    border: 1px solid #ccc !important;
  }

  .ck-toolbar.ck-rounded-corners {
    height: 35px; // Make it smaller than default
  }
}

.ck-focused {
  border: none !important;
}

.tw-dark {
  .ck.ck-balloon-panel.ck-toolbar-container[class*=arrow_s]:after {
    border-top-color: rgb(36, 37, 37);
  }

  .ck.ck-balloon-panel.ck-toolbar-container[class*=arrow_n]:after {
    border-bottom-color: rgb(36, 37, 37);
  }
}
