/* Overrides to bootstrap 4 which is in rem not px units */
/* For simplicity this duplicates _variable_overrides but with rems for font-size variables */
$fa-font-path: '~font-awesome/fonts/';
$font-size-sm: 0.75rem; // 12 px
$font-size-base: 0.8125rem; // 13 px
$font-size-lg: 0.8125rem; //13 px
$font-size-form-control: 0.875rem; // 14 px
$font-family-sans-serif: "Source Sans Pro", "Helvetica Neue", Helvetica, Arial, sans-serif;
$line-height-base: 1.428571429;
$h4-font-size: 1rem;
$h5-font-size: 0.8rem;

$modal-sm: 380px;

$dropdown-link-hover-color: #fff;

$border-radius: 2.5px;
$border-radius-lg: 2.5px;
$border-radius-sm: 2.5px;

$dropdown-link-active-bg: #fff;
$progress-bg: #c4d3d6;
$grid-float-breakpoint: 0px;
$state-success-text: #72c02c;
$alert-success-text: #3c763d;
$state-warning-bg: #fcf8e3;
$table-condensed-cell-padding: 2px 3px;
$panel-body-padding: 10px 15px;
$panel-heading-padding: 6px 10px 6px 15px;
$panel-footer-padding: 10px;
$breadcrumb-separator: '»';
$table-cell-padding: 7px;
$table-accent-bg: #f9f9f9;
$dropdown-item-padding-y: 0.25rem;
$dropdown-item-padding-x: 1.2rem;
$pagination-padding-y-lg: 7.5px;
$pagination-padding-x-lg: 15px;
